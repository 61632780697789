import React, { useEffect } from "react"
import {graphql } from "gatsby"
import { Container, Row, Col } from 'react-bootstrap'
import Layout from "../components/layout"
import SEO from "../components/seo"

import CardI from "../images/icon_dagcoin_card.inline.svg"
import WalletI from "../images/icon_dagcoin_wallet.inline.svg"

import BtnA from "../images/btn_a.inline.svg"


function OnWebsite({ data, pageContext: { lang, currentPath } }) {
  useEffect(() => {

  }, [])
  return (
  <Layout>
    <SEO title="Home" />
      <Container className="acceptingrowpage">
        <Row className="accept-title">
            <h1>Accepting <strong>payments</strong></h1>
            <p>How can customers pay you?</p>
        </Row>
        <Row className="acceptrow">
        <Col>
        <h3>Dagcoin wallet</h3>
        <div className="acceptingpayments">
            <CardI/>
            <p>Possible to accept payments with the Dagpay web application and dedicated POS application.</p> 
            <p>Dagcoin wallet is a free application that enables people to send, receive, and hold dagcoins. The app allows customers to use their dags to pay at merchants worldwide.</p>
        </div>
        <a href="https://dagcoin.org/dagcoin-wallet/" class="gray">Explore more<BtnA/></a>
        </Col>
        <Col>
        <h3>Dagcoin Card</h3>
        <div className="acceptingpayments">
        <WalletI/>
            <p>Possible to accept payments with the Dagpay web application and dedicated POS application.</p> 
            <p className="accepting-margin-b">Dagcoin Card is an easy-to-use contactless payment card allowing you to pay with dags at merchants accepting dagcoins.</p>
        </div>
        <a href="https://card.dagcoin.org/" class="gray">Explore more<BtnA/></a>
        </Col>
        </Row>
      </Container> 

  </Layout>
  )
}
export const query = graphql`
  query {
    websiteill: file(relativePath: { eq: "website_ill.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
export default OnWebsite
